<template>
  <div class="svg_holder" :class="[color, size]">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 450.1 600.22"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M450.1,125.3A22.28,22.28,0,0,0,443,108.47Q391.8,57.82,340.85,7A22.14,22.14,0,0,0,324,0Q202,.17,80,.07c-16,0-32,0-48,0C21.49.13,12.48,3.64,5.81,12.15.77,18.57,0,26.08,0,33.88q0,133,0,266,0,132.73,0,265.47a55.17,55.17,0,0,0,1,12.4c2.61,11.12,13.62,22.53,31.49,22.5q192.48-.4,385-.16c2,0,4.15.33,6-.29,4.95-1.72,10.27-3.1,14.5-6,9.53-6.49,12.09-16.37,12.08-27.52Q449.95,345.78,450.1,125.3ZM342.71,49.84l1.18-.7,55.68,57.75H342.71Zm73.05,522.35q-190.71-.23-381.43,0c-4.9,0-6.08-1.42-6.07-6.17q.16-266,0-531.9c0-5,1.56-6,6.21-6q136.71.2,273.45.1H314c0,3.15,0,5.46,0,7.78.1,24.32-.19,48.66.46,73,.37,13.93,12.27,25.73,26.23,26.09,24.48.63,49,.38,73.47.49,2.31,0,4.62,0,7.84,0v6.1q0,212,.09,423.93C422.11,570.44,421,572.19,415.76,572.19Z"/><path d="M104.55,220.57q120.48,0,241,0a36.71,36.71,0,0,0,6.47-.43,13.69,13.69,0,0,0,10.95-15.92c-1.38-7.83-6.93-11.94-16.44-11.95q-60.74,0-121.48,0-60,0-120,0a33.6,33.6,0,0,0-7.44.62,13.52,13.52,0,0,0-10.37,16.26C88.82,217.08,94.05,220.56,104.55,220.57Z"/><path d="M348.46,361.94q-61.44-.1-122.9,0-62.19,0-124.39,0c-8.18,0-13.66,5.14-14.32,13-.64,7.52,4.44,13.57,12.5,14.73a42.48,42.48,0,0,0,6,.26q119.64,0,239.3,0a48.87,48.87,0,0,0,6.95-.44c5.42-.8,9.15-3.82,10.84-9.06C365.56,370.67,359,362,348.46,361.94Z"/><path d="M345.52,277.24q-120.49,0-241,0a32.23,32.23,0,0,0-7.91.84,13.08,13.08,0,0,0-9.42,15.42c1.55,7.69,6.7,11.36,16.33,11.36q60.49,0,121,0,60.25,0,120.5,0a36.29,36.29,0,0,0,7.44-.61,13.41,13.41,0,0,0,8.14-21.13C356.85,277.77,351.32,277.24,345.52,277.24Z"/><path d="M222.23,446.59q-59.16-.06-118.34,0a29.27,29.27,0,0,0-5.46.4c-5.76,1.09-9.57,4.49-11,10.19-2.54,9.93,3.92,17.61,15.14,17.68,20,.14,40,0,59.92,0,19.64,0,39.29,0,58.93,0a34.81,34.81,0,0,0,6.92-.64c5.92-1.23,9.59-5.36,10.35-11.29C239.94,453,233.59,446.61,222.23,446.59Z"/></g></g></svg>
  </div>
</template>

<script>
export default {
  name: 'IconFile',
  props: ['size', 'color']
}
</script>

<style scoped>
  .svg_holder {
    display: -webkit-flex; /* Safari */
    -webkit-align-items: center; /* Safari 7.0+ */
    display: flex;
    align-items: center;
    -webkit-justify-content:center;
    justify-content:center;
  }
</style>
